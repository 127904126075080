.rufa-navbar {
  font-family: "Arial Bold", Arial, Helvetica, sans-serif;
  position: absolute;
  z-index: 99999;
  width: 100%;
  background-color: white;
  background: white;
  padding: 0 40px;
}

.dropdown-hover-highlight:hover {
  background-color: #457537;
  color: white;
}

.rufa-navbar-dropdown-title-text {
  color: #00000080;
  font-family: Arial;
}

.rufa-navbar-dropdown-title-container-resources,
.rufa-navbar-dropdown-title-container-details {
  padding: 0 1.5rem;
}

.rufa-navbar-search {
  color: #666;
  background-color: #FFFFFF;
  background: white;
}

.rufa-navbar-login-ul {
  margin-bottom: 0;
  padding: 0;
}

.rufa-nav-dropdown-resources {
  position: static;
}

.navbar-collapse {
  margin: 0 -2em;
}

.navbar-collapse.collapsing {
  display: block;
  transition: height 0.3s ease;
  background-color: white;
}

.navbar-collapse.show {
  background-color: white;
}

.navbar .dropdown .dropdown-menu {
  margin: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.navbar-profile {
  padding-right: 2.5em;
  padding-left: 2.2em;
}

.login-dropdown-menu {
  transform: translateX(-100%);
}

.rufa-navbar-dropdown-title-container-resources .dropdown-menu {
  transform: translateX(-45%);
}

.rufa-navbar-dropdown-title-container-details .dropdown-menu {
  transform: translateX(-58%);
}

.rufa-navbar-login-button {
  display: none;
}

@media (min-width: 1406px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0 1.5rem;
  }
}

@media (max-width: 992px) {
  .rufa-navbar {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .navbar-toggler {
    padding: 0;
    border: 0;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }

  .container-fluid.rufa-navbar-search {
    display: inline-block;
  }

  .rufa-navbar-login-button {
    padding-top: 0.5em;
    padding-bottom: 1em;
  }

  .navbar .dropdown .dropdown-menu {
    position: absolute;
  }

  .rufa-navbar-search {
    width: 130%;
    flex-shrink: 0;
    padding-bottom: 55px;
    padding-top: 10px;
  }

  .rufa-navbar-dropdown-title-container-resources .dropdown-menu {
    transform: translateX(-45%);
  }

  .rufa-navbar-dropdown-title-container-details .dropdown-menu {
    transform: translateX(-58%);
  }
}

@media (max-width: 600px) {
  .rufa-navbar-search {
    width: 135%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }

  .rufa-navbar-dropdown-title-container-resources .dropdown-menu {
    transform: translateX(-45%);
  }

  .rufa-navbar-dropdown-title-container-details .dropdown-menu {
    transform: translateX(-58%);
  }
}

@media (max-width: 420px) {
  .rufa-navbar-search {
    width: 120%;
    flex-shrink: 0;
  }

  .rufa-navbar-dropdown-title-container-resources .dropdown-menu {
    transform: translateX(-45%);
  }

  .rufa-navbar-dropdown-title-container-details .dropdown-menu {
    transform: translateX(-58%);
  }
}

@media (max-width: 390px) {
  .rufa-navbar-dropdown-title-container-resources .dropdown-menu {
    transform: translateX(-20%);
  }

  .rufa-navbar-dropdown-title-container-details .dropdown-menu {
    transform: translateX(-20%);
  }
}