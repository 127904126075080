.footer-link-text {
  color: green;
}

.footer-small-logo {
  width: 5em;
  height: 6em;
}

.footer-big-logo {
  width: 18em;
  height: 5em;
}