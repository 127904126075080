.search-container {
  border: 1px solid #00000066;
  position: absolute;
  border-radius: 20px;
  background-color: white;
  line-height: 10px;
  width: 400px;
  padding: 10px;
}

.search-results {
  width: 100%;
  list-style: none;
  overflow-y: auto;
  order: 1px solid rgba(0, 0, 0, 0.4);
  margin: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 0 0 1.5em 1.5em;
  padding-left: 0;
  padding-bottom: 0.5em;
  font-family: "urbane",sans-serif;
  font-weight: 500;
  font-style: normal;
}

.search-results li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 16px;
  color: #000;
  margin-left: -7px;
}

.search-results a {
  color: #000000;
  text-decoration: none;
  padding-right: 10px;
  padding-top: 10px;
  padding-left: 12px;
  display: block;
}

.search-results a:hover {
  background-color: #457537;
  color: white;
}

.focused a {
  background-color: #457537;
  color: white;
}

.search-results::-webkit-scrollbar-track {
  max-width: 600px;
  background: #f9f9f9;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: #457537;
  border-radius: 20px;
  max-width: 600px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.search-input {
  width: 100%;
  border: none;
  padding-left: 6px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.search-no-results {
  list-style-type: none;
}

.search-box-results {
  max-height: 250px;
  overflow-y: scroll;
}

@media (max-width: 992px) {
  .search-container {
    width: 97%;
  }
}

@media (max-width: 500px) {
  .search-results {
    padding-top: 0;
  }
}